//------------------//
// Image hover effect
//------------------//
export function initializeZoomHover() {
    document.querySelectorAll('.zoom-hover').forEach(container => {
        const image = container.querySelector('img');
        const scaleFactor = 1.05; // Scale factor applied on hover
        let targetX = 0, targetY = 0; // Target positions
        let currentX = 0, currentY = 0; // Current positions
        const easingFactor = 0.1; // How quickly the image catches up
        let isHovered = false; // Track whether the container is hovered

        if (image) {
            container.addEventListener('mouseenter', () => {
                isHovered = true;
                image.style.transition = 'transform 1s ease-out'; // Smooth transition
                image.style.transform = `scale(${scaleFactor})`;
            });

            container.addEventListener('mousemove', (e) => {
                if (!isHovered) return;

                const { width, height, top, left } = container.getBoundingClientRect();
                const x = (e.clientX - left) / width;
                const y = (e.clientY - top) / height;

                const maxX = (width * (scaleFactor - 1)) / 4;
                const maxY = (height * (scaleFactor - 1)) / 4;

                targetX = Math.round((x - 0.5) * maxX * 2);
                targetY = Math.round((y - 0.5) * maxY * 2);
            });

            function animate() {
                if (isHovered) {
                    currentX += (targetX - currentX) * easingFactor;
                    currentY += (targetY - currentY) * easingFactor;

                    image.style.transform = `translate(${-currentX}px, ${-currentY}px) scale(${scaleFactor})`;
                }

                requestAnimationFrame(animate);
            }

            container.addEventListener('mouseleave', () => {
                isHovered = false;
                image.style.transition = 'transform 2s ease-out'; // Smooth return
                image.style.transform = 'translate(0, 0) scale(1)';
            });

            animate(); // Start the animation loop
        }
    });
}