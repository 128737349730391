// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}

import { debounce } from './utilities.js';

//------------------//
// GSAP
//------------------//
import { gsap } from "gsap";
import './gsap.js';
window.gsap = gsap;

document.querySelectorAll('[data-mobile-nav-drawer]').forEach((drawer) => {
    gsap.set(drawer, { display: 'none' });
});

function openNavDrawer(mobileNav, callback) {
    const drawer = document.querySelector(`[data-mobile-nav-drawer="${mobileNav}"]`);
    const fade_in_items = drawer.querySelectorAll('[data-menu-fade-item]');

    const tl = gsap.timeline();

    tl.set(drawer, { display: '' })
    .fromTo(drawer, {
        x: "100%"
    }, {
        x: 0,
        duration: .3,
    })

    .fromTo(fade_in_items, {
        opacity: 0,
        y: 20
    }, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        stagger: 0.05,
    });

    tl.play(0).then(() => {
        if (callback) {
            callback();
        }
    });

}

function closeNavDrawer(mobileNav, callback) {
    const drawer = document.querySelector(`[data-mobile-nav-drawer="${mobileNav}"]`);
    const fade_in_items = drawer.querySelectorAll('[data-menu-fade-item]');

    const tl = gsap.timeline();

    tl
    .to(drawer, {
        x: "100%",
        duration: .3,
    })
    .set(drawer, { display: 'none' });

    tl.play(0).then(() => {
        if (callback) {
            callback();
        }
    });

}


//------------------//
// Alpine.js
//------------------//
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';
window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(persist);
Alpine.plugin(focus);

Alpine.data('globalBody', () => ({
    mobileMenuOpen: false,
    searchIsOpen: false,
    activeMobileNavItem: null,
    dropdowns: {},
    backgroundBlurActive: false,
    mobileLinks: false,

    openMobileMenuDrawer() {
        this.activeMobileNavItem = this.$event.target.dataset.mobileNavDrawerBtn;

        this.$nextTick(() => {
            openNavDrawer(this.activeMobileNavItem, () => {
                if (this.activeMobileNavItem) {
                    this.$focus.within(document.querySelector(`[data-mobile-nav-drawer="${this.activeMobileNavItem}"]`)).first();
                }
            });
        });
    },

    closeMobileMenuDrawer() {
        if (this.activeMobileNavItem) {
            let drawer = this.activeMobileNavItem;
            this.activeMobileNavItem = null;

            closeNavDrawer(drawer);
        }
    },

    toggleDropdown(id) {
        if (this.dropdowns[id]) {
            this.dropdowns[id] = false;
        } else {
            this.dropdowns[id] = true;

            const fade_in_items = this.$refs[id].querySelectorAll('[data-menu-fade-item]');

            gsap.fromTo(fade_in_items, {
                opacity: 0,
                y: 20
            }, {
                opacity: 1,
                y: 0,
                duration: 0.5,
                stagger: 0.05,
            });
        }
        this.updateBackgroundBlur();
    },

    updateBackgroundBlur() {
        this.backgroundBlurActive = this.searchIsOpen || this.mobileMenuOpen || Object.values(this.dropdowns).includes(true);
    },

    body: {
        [':class']() {
            return {
                'overflow-hidden': this.searchIsOpen || this.mobileMenuOpen || Object.values(this.dropdowns).includes(true)
            }
        },
        ['@keydown.escape']() {
            this.searchIsOpen = false;
            this.mobileMenuOpen = false;

            if(this.activeMobileNavItem) {
                let drawer = this.activeMobileNavItem;
                this.activeMobileNavItem = null;

                closeNavDrawer(drawer);
            }

            Object.keys(this.dropdowns).forEach(id => {
                this.dropdowns[id] = false;
            });

            this.updateBackgroundBlur();
        },
        ['@scroll.window']() {
            const { scrollTop, scrollHeight, clientHeight } = this.$event.target.scrollingElement;
            const bottomPosition = scrollHeight - clientHeight - scrollTop;

            this.mobileLinks = scrollTop > 150 && bottomPosition > 300;
            if (!this.mobileLinks) {
                this.searchIsOpen = false;
            }
            this.updateBackgroundBlur();
        },
        ['@resize.window.debounce']() {
            if(window.innerWidth > 1279) {
                this.mobileMenuOpen = false;

                if(this.activeMobileNavItem) {
                    closeNavDrawer(this.activeMobileNavItem, () => {
                        console.log('drawer closed');
                        this.activeMobileNavItem = null;
                    });
                }
            }
            this.updateBackgroundBlur();
        }
    },
    mobileHamburger: {
        ['@click']() {
            this.mobileMenuOpen = !this.mobileMenuOpen;

            if (!this.mobileMenuOpen && this.activeMobileNavItem) {
                let drawer = this.activeMobileNavItem;
                this.activeMobileNavItem = null;

                closeNavDrawer(drawer);
            }
            this.updateBackgroundBlur();
        }
    }
}));

Alpine.start();




//------------------//
// Fancybox
//------------------//
import { Fancybox, Panzoom } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "@fancyapps/ui/dist/panzoom/panzoom.css";
import '../css/_vendor/fancybox.scss';
window.Fancybox = Fancybox;
Fancybox.bind("[data-fancybox]", {
    autoFocus: false,
});
Fancybox.bind("[data-fancybox='gallery']", {
    mainClass: 'fancybox-gallery',
    compact: false,
    idle: false,
    showClass: "fancybox-customSlideIn",
    Images: {
        zoom: false,
    },
    Toolbar: {
        display: {
            left: [],
            middle: [],
            right: ['close'],
        },
    },
});



//------------------//
// Panzoom - For Seating Charts
//------------------//
document.addEventListener("DOMContentLoaded", () => {
    const container = document.getElementById("panzoom-container");
    const options = {
        click: false,
        dblClick: "iterateZoom",
        wheel: false,
    };

    if (container) {
        const panzoom = new Panzoom(container, options);

        document.getElementById("zoom-in").addEventListener("click", () => panzoom.zoomIn());
        document.getElementById("zoom-out").addEventListener("click", () => panzoom.zoomOut());
        document.getElementById("reset").addEventListener("click", () => panzoom.reset());
    }
});



//------------------//
// Image Hover Effect
//------------------//
import { initializeZoomHover } from './zoom-hover.js';

// Initial call to initialize zoom hover effect
initializeZoomHover();

document.addEventListener('htmx:afterSwap', () => {
    initializeZoomHover();
});



//------------------//
// Import Tippy.js & CSS and attach to Window
// Tippy is used for the calendar event tooltips
//------------------//
import tippy, {followCursor} from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
window.tippy = tippy;
window.followCursor = followCursor;



//------------------//
// Calculates header height and sets it as a CSS variable
//------------------//
document.addEventListener('DOMContentLoaded', () => {
    const siteHeader = document.querySelector('#site-header');
    const updateHeaderHeight = () => {
        const headerHeight = siteHeader.offsetHeight;
        document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    };

    // Initial height update
    updateHeaderHeight();

    // Update height on window resize
    window.addEventListener('resize', debounce(updateHeaderHeight, 100));
});