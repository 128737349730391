import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from 'gsap/SplitText';
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, SplitText);

//------------------//
// Heading Circle Animations
//------------------//

// Select all .heading-highlight elements that contain .highlight-svg path
document.querySelectorAll(".heading-highlight").forEach((heading) => {
    const svgPaths = heading.querySelectorAll(".highlight-svg path");

    if (svgPaths.length > 0) {
        // Randomly generate a few properties
        const randomRotation    = gsap.utils.random(-5, 5);
        const randomScaleX      = gsap.utils.random([-1, 1]) * gsap.utils.random(0.9, 1.25);
        const randomScaleY      = gsap.utils.random(0.9, 1.2);

        // Apply random properties to the highlight-svg
        gsap.set(heading.querySelector(".highlight-svg"), {
            rotate: randomRotation,
            scaleX: randomScaleX,
            scaleY: randomScaleY,
            x: '-50%',
            y: '-50%'
        });

        // Animate the path
        gsap.from(
            svgPaths,
            {
                drawSVG: "0%",
                duration: gsap.utils.random(0.75, 1.5),
                ease: "power2.inOut",
                scrollTrigger: {
                    trigger: heading,
                    start: "bottom 75%",
                }
            }
        );
    }
});

document.querySelectorAll(".image-brushstroke").forEach((image) => {
    const svgPaths = image.querySelectorAll(".brushstroke-svg path");

    if (svgPaths.length > 0) {
        // Randomly generate a few properties
        const randomRotation    = gsap.utils.random(-10, 10);
        const randomScaleX      = gsap.utils.random([-1, 1]) * gsap.utils.random(0.9, 1.1);
        const randomScaleY      = gsap.utils.random(0.9, 1.2);

        // Apply random properties to the highlight-svg
        gsap.set(image.querySelector(".brushstroke-svg"), {
            rotate: randomRotation,
            scaleX: randomScaleX,
            scaleY: randomScaleY,
            x: '0',
            y: '0'
        });

        // Animate the path
        gsap.from(
            svgPaths,
            {
                drawSVG: "0%",
                duration: gsap.utils.random(0.75, 1.5),
                ease: "power2.inOut",
                scrollTrigger: {
                    trigger: image,
                    start: "bottom 75%",
                }
            }
        );
    }
});


// function titleAnimations() {
//     const titles = gsap.utils.toArray('.animated-title');

//     titles.forEach((title) => {
//         let splitTitle = new SplitText(title, { type: 'words' });
//         let words = splitTitle.words;

//         gsap.from(words, {
//             opacity: 0,
//             y: 10,
//             ease: 'power2.out',
//             stagger: 0.1,
//             duration: 0.5,
//             scrollTrigger: {
//                 trigger: title,
//                 // markers: true,
//                 id: 'Animated Titles',
//                 start: 'top bottom-=100px',
//                 end: 'top bottom-=100px',
//             }
//         });
//     });
// }

// titleAnimations();


// Default options
let options = {
    once: true
}
let scroll_animations = [];
const scroll_animation_items = document.querySelectorAll('[data-scroll-animation]');

function getMutationObserver() {
  return window.MutationObserver ||
    window.WebKitMutationObserver ||
    window.MozMutationObserver;
}

function isSupported() {
  return !!getMutationObserver();
}

function scrollingAnimations(settings) {
    options = Object.assign(options, settings);

    scroll_animation_items.forEach((element, index) => {

        let default_animation_start = { autoAlpha: 0 };
        let default_animation_end = { autoAlpha: 1, duration: 0.75, ease: 'power2.out', clearProps: 'all' };
        let animation_start = {};
        let animation_end = {};

        const type = element.getAttribute('data-scroll-animation').trim();
        const duration = element.getAttribute('data-scroll-animation-duration');
        const offset = element.getAttribute('data-scroll-animation-offset');
        const delay = element.getAttribute('data-scroll-animation-delay');
        const once = element.getAttribute('data-scroll-animation-once');
        const clamp = element.getAttribute('data-scroll-animation-clamp');
        const trigger = element.getAttribute('data-scroll-animation-trigger') || element;


        if(type === 'fade-right') {
            animation_start = { x: '-25px' };
            animation_end = { x: 0 };
        }

        if(type === 'fade-left') {
            animation_start = { x: '25px' };
            animation_end = { x: 0 };
        }

        if(type === 'fade-up') {
            animation_start = { y: '25px' };
            animation_end = { y: 0 };
        }

        if(type === 'fade-down') {
            animation_start = { y: '-25px' };
            animation_end = { y: 0 };
        }

        if(duration) {
            default_animation_end.duration = duration / 1000; // duration in milliseconds
        }

        if(delay) {
            default_animation_end.delay = delay / 1000; // delay in milliseconds
        }

        const tl = gsap.timeline({
            smoothChildTiming: true,
            scrollTrigger: {
                id: `scroll-animation-${index}`,
                trigger: trigger,
                start: (/%$/.test(offset)) ? `${offset} bottom` : offset ? `top bottom-=${offset}px` : 'top bottom-=100px',
                end: 'bottom bottom-=100px',
                toggleActions: once || options.once ? 'play none none none' : 'play none none reverse',
                // markers: true,
            }
        });

        tl.fromTo(element, { ...animation_start, ...default_animation_start }, {
            ...animation_end,
            ...default_animation_end,
        });

        scroll_animations.push(tl);

    });

    window.scroll_animations = scroll_animations;

}
scrollingAnimations();